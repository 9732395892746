import { useState, useContext, useRef, useEffect } from 'react';
import { START_DATE, useDatepicker, useMonth } from '@datepicker-react/hooks';
import { Month } from './Month';
import DatepickerContext from './DatepickerContext';
import { styled } from '@stitches/react';
import { useOnClickOutside } from '../../../hooks/useOutsideClick';
import {
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';
import { CustomCalendarIcon } from '../../../theme/icons/CustomCalendarIcon';
import EventsContext from '../../../store/client/EventsContext';
import RedirectionContext from '../../../context/RedirectionContext';
import PassContext from '../../../store/client/PassContext';

const Wrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  borderRadius: '25px',
  backgroundColor: 'transparent',
});

const Container = styled('div', {
  position: 'absolute',
  zIndex: '15',
  left: 0,
  top: 60,
  borderRadius: '15px',
  width: 300,
  padding: '16px 24px',
  fontFamily: 'Inter Medium, sans-serif',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  variants: {
    top: {
      placementTop: {
        top: -372,
      },
    },
    dashboard: {
      backgroundColor: '#000',
    },
    backgroundColor: {
      darkMode: {
        backgroundColor: '#3B4851',
      },
    },
  },
});

const CloseButton = styled('button', {
  cursor: 'pointer',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#29323A',
  variants: {
    color: {
      darkMode: {
        color: '#ffff',
      },
    },
  },
  '&:hover': {
    color: ' #FF8477',
  },
});

const NavBtn = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  width: '30px',
  height: '30px',
  borderRadius: '100%',
  backgroundColor: '#DDDDDD',
  color: '#29323A',
  '&:hover': {
    backgroundColor: '#FF8477',
    color: '#fff',
  },
});

const ResetDatesButton = styled('button', {
  borderRadius: '20px',
  backgroundColor: '#DDDDDD',
  color: '#29323A',
  padding: '8px 18px',
  fontSize: 14,
  '&:hover': {
    backgroundColor: '#FF8477',
    color: '#fff',
  },
});

export function Datepicker({
  setDate,
  placementTop,
  darkMode,
  isEdit,
  startDate,
  endDate,
  expiryDate,
  gateDate,
  minBookingDate,
  maxBookingDate,
}) {
  const [pickerOpen, setPickerOpen] = useState(null);
  const [state, setState] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
  });
  const closeOnOutsideClick = useRef();
  useOnClickOutside(closeOnOutsideClick, () => setPickerOpen(false));
  const [isSmallerThan768px] = useMediaQuery('(max-width: 768px)');
  const [isLargerThan768px] = useMediaQuery('(min-width: 768px)');
  const redirectCtx = useContext(RedirectionContext);
  const eventCtx = useContext(EventsContext);
  const passCtx = useContext(PassContext);

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
    goToDate,
    onResetDates,
  } = useDatepicker({
    startDate: state.startDate,
    minBookingDate,
    maxBookingDate,
    endDate: state.endDate,
    focusedInput: state.focusedInput,
    firstDayOfWeek: 1,
    numberOfMonths: 1,
    exactMinBookingDays: true,
    onDatesChange: (data) => {
      if (!data.focusedInput) {
        setState({ ...data, focusedInput: START_DATE });
      } else {
        setState(data);
      }
    },
    isDateBlocked: (date) => {
      // Block dates beyond maxBookingDate and before minBookingDate
      if (
        (maxBookingDate && date > new Date(maxBookingDate)) ||
        (minBookingDate && date < new Date(minBookingDate))
      ) {
        return true;
      }
      return false;
    },
  });
  const month = activeMonths[0];
  const { monthLabel } = useMonth({
    year: month.year,
    month: month.month,
    firstDayOfWeek,
  });

  function getFormattedDate(date) {
    if (!date) return '';

    let dd = date.getDate();
    let mm = date.getMonth() + 1;

    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '/' + mm + '/' + yyyy;
  }

  useEffect(() => {
    setDate(state?.startDate);
  }, [setDate, state?.startDate]);

  // create mode
  useEffect(() => {
    // create mode - clicked add new pass template or add new reader
    if (
      redirectCtx.addReaderFromEventState ||
      redirectCtx.addTemplateFromEventState
    ) {
      if (startDate) {
        setState({
          startDate: redirectCtx.tempEventState.startDate,
          focusedInput: START_DATE,
        });
      } else if (endDate) {
        setState({
          startDate: redirectCtx.tempEventState.endDate,
          focusedInput: START_DATE,
        });
      } else if (gateDate) {
        setState({
          startDate: redirectCtx.tempEventState.gateDate,
          focusedInput: START_DATE,
        });
      }
    }
  }, [
    endDate,
    gateDate,
    redirectCtx.addReaderFromEventState,
    redirectCtx.addTemplateFromEventState,
    redirectCtx.tempEventState.endDate,
    redirectCtx.tempEventState.gateDate,
    redirectCtx.tempEventState.startDate,
    startDate,
  ]);

  // edit mode
  useEffect(() => {
    if (isEdit) {
      // edit mode - clicked add new pass template or add new reader
      if (
        redirectCtx.addTemplateFromEditEventState ||
        redirectCtx.addReaderFromEventState ||
        redirectCtx.addTemplateFromEventState ||
        redirectCtx.addReaderFromEditEventState
      ) {
        if (startDate) {
          setState({
            startDate: redirectCtx.tempEventState.startDate,
            focusedInput: START_DATE,
          });
        } else if (endDate) {
          setState({
            startDate: redirectCtx.tempEventState.endDate,
            focusedInput: START_DATE,
          });
        } else if (gateDate) {
          setState({
            startDate: redirectCtx.tempEventState.gateDate,
            focusedInput: START_DATE,
          });
        }
        // edit mode - regular first time load
      } else {
        if (expiryDate) {
          if (passCtx.passState.expiryDate) {
            setState({
              startDate: passCtx.passState.expiryDate,
              focusedInput: START_DATE,
            });
          }
        } else {
          if (startDate) {
            setState({
              startDate: eventCtx.eventState.startDate,
              focusedInput: START_DATE,
            });
          } else if (endDate) {
            setState({
              startDate: eventCtx.eventState.endDate,
              focusedInput: START_DATE,
            });
          } else if (gateDate) {
            setState({
              startDate: eventCtx.eventState.gateDate,
              focusedInput: START_DATE,
            });
          }
        }
      }
    }
  }, [
    endDate,
    eventCtx.eventState.endDate,
    eventCtx.eventState.gateDate,
    eventCtx.eventState.startDate,
    gateDate,
    isEdit,
    redirectCtx.addReaderFromEditEventState,
    redirectCtx.addReaderFromEventState,
    redirectCtx.addTemplateFromEditEventState,
    redirectCtx.addTemplateFromEventState,
    redirectCtx.tempEventState.endDate,
    redirectCtx.tempEventState.gateDate,
    redirectCtx.tempEventState.startDate,
    setDate,
    startDate,
    expiryDate,
    passCtx.passState.expiryDate,
  ]);

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
        setPickerOpen,
        minDate: minBookingDate,
        maxDate: maxBookingDate,
      }}
    >
      <Wrapper>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<CustomCalendarIcon boxSize="20px" />}
            pl="5px"
            zIndex="1"
          />
          <Input
            pl="55px"
            pt="5px"
            readOnly
            sx={{
              cursor: 'pointer',
            }}
            variant="filledForDarkBg"
            onClick={() => {
              setPickerOpen(true);
            }}
            value={getFormattedDate(state.startDate)}
            onChange={(e) => {
              // if (!e.target.value) {
              //   onResetDates();
              // }
              // if (!isNaN(Date.parse(e.target.value)) && e.target.value.length === 10) {
              //   const date = new Date(e.target.value);
              //   console.log('date', date);
              setState({
                startDate: new Date(e.target.value),
                focusedInput: START_DATE,
              });
              goToDate(new Date(e.target.value));
              //   }
            }}
            placeholder="Select date"
          />
        </InputGroup>

        {pickerOpen && isLargerThan768px && (
          <Container
            ref={closeOnOutsideClick}
            top={placementTop && 'placementTop'}
            backgroundColor={darkMode && 'darkMode'}
          >
            <Flex justifyContent="flex-end">
              <CloseButton
                onClick={() => setPickerOpen(false)}
                type="button"
                color={darkMode && 'darkMode'}
              >
                X
              </CloseButton>
            </Flex>
            <Flex justifyContent="center" alignItems="center" mb={3}>
              <Text color={darkMode && '#fff'}>{monthLabel}</Text>
            </Flex>
            <Month
              darkMode={darkMode}
              key={`${month.year}-${month.month}`}
              year={month.year}
              month={month.month}
              firstDayOfWeek={firstDayOfWeek}
            />
            <Flex justifyContent="space-between" mt={3}>
              <HStack spacing={3}>
                <NavBtn onClick={goToPreviousMonths} type="button">
                  <CustomChevronLeftIcon />
                </NavBtn>
                <NavBtn onClick={goToNextMonths} type="button">
                  <CustomChevronLeftIcon transform="rotate(180deg)" />
                </NavBtn>
              </HStack>
              <ResetDatesButton onClick={() => onResetDates()} type="button">
                Reset dates
              </ResetDatesButton>
            </Flex>
          </Container>
        )}
        {pickerOpen && isSmallerThan768px && (
          <Container
            ref={closeOnOutsideClick}
            backgroundColor={darkMode && 'darkMode'}
          >
            <Flex justifyContent="flex-end">
              <CloseButton
                onClick={() => setPickerOpen(false)}
                type="button"
                color={darkMode && 'darkMode'}
              >
                X
              </CloseButton>
            </Flex>
            <Flex justifyContent="center" alignItems="center" mb={3}>
              <Text color={darkMode && '#fff'}>{monthLabel}</Text>
            </Flex>
            <Month
              darkMode={darkMode}
              key={`${month.year}-${month.month}`}
              year={month.year}
              month={month.month}
              firstDayOfWeek={firstDayOfWeek}
            />
            <Flex justifyContent="space-between" mt={3}>
              <HStack spacing={3}>
                <NavBtn onClick={goToPreviousMonths} type="button">
                  <CustomChevronLeftIcon />
                </NavBtn>
                <NavBtn onClick={goToNextMonths} type="button">
                  <CustomChevronLeftIcon transform="rotate(180deg)" />
                </NavBtn>
              </HStack>
              <ResetDatesButton onClick={() => onResetDates()} type="button">
                Reset dates
              </ResetDatesButton>
            </Flex>
          </Container>
        )}
      </Wrapper>
    </DatepickerContext.Provider>
  );
}
