import { Badge, Box, Circle, Flex, HStack, Text, useMediaQuery } from '@chakra-ui/react';

const LiveEventDataRenderStatus = ({ value, errorMsg, inDrawer }) => {
  const [isSmallerThan540px] = useMediaQuery('(max-width: 540px)');
  const renderErrorMsg = (error) => {
    switch (error) {
      case 'reader_disabled':
        return `Reader is disabled`;
      case 'deactivated_pass':
        return `Pass has been deactivated`;
      case 'event_passed':
        return `Event has passed`;
      case 'user_disabled_by_admin':
        return `Event organiser’s account is disabled`;
      case 'reader_not_assigned_to_event':
        return `Reader is not assigned to the event`;
      case 'within_validity_period':
        return `Not within validity period`;
      default:
        return null;
    }
  };
  return value ? (
    isSmallerThan540px && !inDrawer ? (
      <Circle bg="success" size="15px" />
    ) : (
      <Badge>Accepted</Badge>
    )
  ) : !value ? (
    isSmallerThan540px && !inDrawer ? (
      <Circle bg="error" size="15px" />
    ) : (
      <Flex direction="column" alignItems="flex-start">
        <Flex
          bg="#ddd"
          borderRadius="20px"
          justifyContent="center"
          alignItems="center"
          maxWidth="100px"
        >
          <HStack pr="12px" pl="12px">
            <Circle bg="error" size="12px" />
            <Box fontSize="12px" top="1px" pos="relative" pb="1px" color="secondaryDark">
              Rejected
            </Box>
          </HStack>
        </Flex>
        <Text fontSize="11px" lineHeight="16px" mt="4px">
          {renderErrorMsg(errorMsg)}
        </Text>
      </Flex>
    )
  ) : (
    <Badge color="error">n/a</Badge>
  );
};

export default LiveEventDataRenderStatus;
