import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';

function MessagesHeader() {
  return (
    <>
      <Flex
        flexDirection={{ base: 'column', sm: 'row' }}
        flexWrap="wrap"
        mt="15px"
        pt="9px"
        minH="54px"
      >
        <Flex
          gap={{ base: '10px' }}
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="wrap"
          mb={{ base: '10px', sm: '0px' }}
        >
          <Heading alignSelf={{ base: 'flex-start', sm: 'center' }}>
            New message
          </Heading>
        </Flex>
      </Flex>
    </>
  );
}

export default MessagesHeader;
