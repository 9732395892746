import { Badge, Box, Circle, Flex, HStack } from '@chakra-ui/react';

const EventsRenderStatus = ({ value }) => {
  return (
    <>
      {value === 'ongoing' ? (
        <Flex
          bg="#ddd"
          borderRadius="20px"
          justifyContent="center"
          alignItems="center"
          maxWidth="100px"
        >
          <HStack pr="12px" pl="12px">
            <Circle bg="success" size="12px" />
            <Box
              fontSize="12px"
              top="1px"
              pos="relative"
              pb="1px"
              color="secondaryDark"
            >
              Ongoing
            </Box>
          </HStack>
        </Flex>
      ) : value === 'upcoming' ? (
        <Badge>Upcoming</Badge>
      ) : value === 'ended' ? (
        <Badge variant="error02">Ended</Badge>
      ) : (
        <Box color="error">n/a</Box>
      )}
    </>
  );
};

export default EventsRenderStatus;
