import {
  Badge,
  Box,
  Circle,
  useColorModeValue,
  Flex,
  HStack,
  Tooltip,
} from '@chakra-ui/react';

const PassesRenderStatus = ({ value, inDrawer, hasTooltip }) => {
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');
  const tooltipWidth = value === 'active' ? '197px' : '170px';

  const tooltipMessage = {
    issued: 'Pass has been sent.',
    active: 'Pass has been added to a wallet.',
    expired:
      'Pass can not be added to a wallet or scanned as the event has passed.',
    pending: 'Pass has been sent, but not added to a wallet yet.',
    removed: 'Pass has been removed from a wallet.',
    deactivated:
      'Pass can not be added to a wallet or scanned as it was deactivated by issuer.',
  };

  return (
    <>
      {hasTooltip ? (
        <Tooltip
          label={`${tooltipMessage[value]}`}
          hasArrow
          placement="top"
          fontSize="12px"
          color={textTooltip}
          w={tooltipWidth}
          borderRadius="6px"
          bg={bgTooltip}
          textAlign="center"
          p="10px"
          mb="5px"
          closeOnClick={false}
        >
          {value === 'deactivated' ? (
            <Flex
              bg="#ddd"
              borderRadius="20px"
              justifyContent="center"
              alignItems="center"
              maxWidth="100px"
            >
              <HStack pr="12px" pl="12px">
                <Circle bg="error" size="12px" />
                <Box
                  fontSize="12px"
                  top="1px"
                  pos="relative"
                  pb="1px"
                  color="secondaryDark"
                >
                  Deactivated
                </Box>
              </HStack>
            </Flex>
          ) : value === 'issued' ? (
            <Badge variant="issued">Issued</Badge>
          ) : value === 'active' ? (
            <Badge>Active</Badge>
          ) : value === 'expired' ? (
            <Badge variant="error02">Expired</Badge>
          ) : value === 'pending' ? (
            <Badge variant="pending">Pending</Badge>
          ) : value === 'removed' ? (
            <Badge variant="removed">Removed</Badge>
          ) : (
            <Box color="error">n/a</Box>
          )}
        </Tooltip>
      ) : (
        <>
          {value === 'deactivated' ? (
            <Flex
              bg="#ddd"
              borderRadius="20px"
              justifyContent="center"
              alignItems="center"
              maxWidth="100px"
            >
              <HStack pr="12px" pl="12px">
                <Circle bg="error" size="12px" />
                <Box
                  fontSize="12px"
                  top="1px"
                  pos="relative"
                  pb="1px"
                  color="secondaryDark"
                >
                  Deactivated
                </Box>
              </HStack>
            </Flex>
          ) : value === 'issued' ? (
            <Badge variant="issued">Issued</Badge>
          ) : value === 'active' ? (
            <Badge>Active</Badge>
          ) : value === 'expired' ? (
            <Badge variant="error02">Expired</Badge>
          ) : value === 'pending' ? (
            <Badge variant="pending">Pending</Badge>
          ) : value === 'removed' ? (
            <Badge variant="removed">Removed</Badge>
          ) : (
            <Box color="error">n/a</Box>
          )}
        </>
      )}
    </>
  );
};

export default PassesRenderStatus;
