import {
  Container,
  Divider,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import MessagesHeader from '../../components/client/messages/MessagesHeader';
import MessagesCreatePage from './MessagesCreatePage';
import MessagingTermsModal from '../../components/modals/MessagingTermsModal';
import { useEffect } from 'react';
import useOrganizationStatus from '../../hooks/useOrganisationStatus';
import EmptyArtistAnimated from '../../theme/illustrations-animated/EmptyArtistAnimated';
import EmptyArtistDarkAnimated from '../../theme/illustrations-animated/EmptyArtistDarkAnimated';
import { MessageContextProvider } from '../../store/client/MessageContext';

const MessagesPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modeSvgIcon = useColorModeValue(
    <EmptyArtistAnimated />,
    <EmptyArtistDarkAnimated />
  );

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Messaging',
      linkUrl: '/messaging',
    },
  ];

  const { components, shouldRender } = useOrganizationStatus(modeSvgIcon);

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <Container display="flex" flexDir="column" flex="1" pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <MessagesHeader />
      <Divider borderColor="primaryBackground" mb={6} />

      {components}

      {shouldRender && (
        <>
          <MessageContextProvider>
            <MessagesCreatePage />
            <MessagingTermsModal isOpen={isOpen} onClose={onClose} />
          </MessageContextProvider>
        </>
      )}
    </Container>
  );
};
export default MessagesPage;
