import { useMediaQuery } from '@chakra-ui/react';
import { Container, Flex, Box } from '@chakra-ui/react';
import Footer from './Footer';
import ScrollToTopForRouter from '../components/common/ScrollToTopForRouter';
import passentryIntroSrc from '../assets/images/passentry-intro.webp';
import passentryIntroVideoSrc from '../assets/videos/passentry-intro.mp4';
import useGATracker from '../hooks/useGATracker';
// import Lottie from 'lottie-react';
// import animationData from '../lotties/lock.json';
// import animationData from '../lotties/mobiles.json';
// import { animationData } from '../lotties/mobilesAdjusted';

const LayoutAuth = (props) => {
  // google analytics integration
  useGATracker();

  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  return (
    <>
      <ScrollToTopForRouter />
      <Flex direction="column" minH="100vh">
        {props.header}
        <Container variant="bodyContent">
          <Flex w="100%" justifyContent={{ base: 'center', md: 'flex-start' }}>
            {isLargerThanMD && (
              <Box w="45%">
                <Box
                  overflow="hidden"
                  h="full"
                  maxH="100vh"
                  borderBottomRightRadius="25px"
                  position="sticky"
                  top="0"
                  backgroundColor="secondaryDark"
                  // backgroundImage="url('./images/passentry-intro.webp')"
                  backgroundImage={`url(${passentryIntroSrc})`}
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                >
                  {/* Approach 1: */}
                  {/* <Lottie
                    animationData={animationData}
                    loop="true"
                    autoplay="true"
                    style={{ height: '100%' }}
                  /> */}
                  {/* Approach 2: */}
                  {/* <Image
                    w="full"
                    h="full"
                    pos="absolute"
                    objectFit="cover"
                    loading="lazy"
                    src={passentryIntroSrc}
                    alt="PassEntry Welcome"
                  /> */}
                  {/* Approach 3: */}
                  <Box
                    as="video"
                    muted
                    autoPlay
                    loop
                    // controls
                    // src="https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"
                    // src="./videos/passentry-intro.mp4"
                    src={passentryIntroVideoSrc}
                    // poster="./images/passentry-intro.webp"
                    poster={passentryIntroSrc}
                    alt="PassEntry"
                    position="absolute"
                    objectFit="cover"
                    w="full"
                    h="full"
                    pointerEvents="none"
                    // sx={{
                    //   aspectRatio: '16/9',
                    // }}
                  />
                </Box>
              </Box>
            )}
            <Box
              pr="16px"
              pl={{ base: '16px', md: '5%', xl: '10%' }}
              flex={{ base: 1, sm: 'none' }}
            >
              {props.children}
            </Box>
          </Flex>
        </Container>
        <Footer />
      </Flex>
    </>
  );
};

export default LayoutAuth;
