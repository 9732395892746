import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Show,
  Hide,
  Text,
  useColorModeValue,
  Link,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import SliderInput from '../../common/SliderInput';
import SearchableMap from '../../common/SearchableMap';
import { TextareaAutosize } from '../../common/TextareaAutosize';
import { CustomPlusIcon } from '../../../theme/icons/CustomPlusIcon';
import CustomToast from '../../../common/CustomToast';

function LocationDetails(props) {
  const {
    isEdit,
    activeStep,
    editedFields,
    setEditedFields,
    handleLocationCountChange,
  } = props;
  const passTemplateCtx = useContext(PassTemplateContext);
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const [radius, setRadius] = useState(100);
  const [recenterMap, setRecenterMap] = useState(false);
  const toast = useToast();

  const handleSetRadius = (updatedRadius) => {
    passTemplateCtx.updateLocations(
      passTemplateCtx.locations.map((loc, i) =>
        i === activeStep ? { ...loc, radius: updatedRadius } : loc
      )
    );
    setRadius(updatedRadius);
    if (isEdit) {
      setEditedFields({
        ...editedFields,
        locations: true,
      });
    }
  };

  const handleRemoveLocation = () => {
    if (passTemplateCtx.locations[activeStep]) {
      passTemplateCtx.updateLocations(
        passTemplateCtx.locations.filter((loc, i) => i !== activeStep)
      );
      passTemplateCtx.updateLocationCount(passTemplateCtx.locations.length - 1);
      if (isEdit) {
        setEditedFields({
          ...editedFields,
          locations: true,
        });
      }
    } else {
      passTemplateCtx.updateLocationCount(passTemplateCtx.locationCount - 1);
    }
    toast({
      render: (props) => (
        <CustomToast
          status="success"
          title="Location removed"
          description=""
          onClose={props.onClose}
        />
      ),
    });
  };

  const handleAddressClick = () => {
    setRecenterMap(true);
  };

  useEffect(() => {
    setRadius(
      passTemplateCtx.locations[activeStep]
        ? passTemplateCtx.locations[activeStep].radius
        : 100
    );
    setRecenterMap(false);
  }, [activeStep]);

  return (
    <>
      <FormControl bg={modeFormControlBg} borderRadius="0 15px 0 0" isRequired>
        <Flex alignItems="center">
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0}>Name</FormLabel>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl">
              <FormLabel>Name</FormLabel>
            </Show>
            <Input
              value={passTemplateCtx.locations[activeStep]?.name || ''}
              variant="filledForDarkBg"
              id="templateName"
              type="text"
              placeholder="Type a location name here..."
              onChange={(e) => {
                passTemplateCtx.updateLocations(
                  passTemplateCtx.locations.map((loc, i) =>
                    i === activeStep ? { ...loc, name: e.target.value } : loc
                  )
                );
                if (isEdit) {
                  setEditedFields({
                    ...editedFields,
                    locations: true,
                  });
                }
              }}
              onBlur={() => {
                const value = passTemplateCtx.locations[activeStep]?.name;
                if (value.trim() === '') {
                  passTemplateCtx.updateLocations(
                    passTemplateCtx.locations.map((loc, i) =>
                      i === activeStep ? { ...loc, name: '' } : loc
                    )
                  );
                  if (isEdit) {
                    setEditedFields({
                      ...editedFields,
                      locations: true,
                    });
                  }
                }
              }}
              autoComplete="off"
              maxLength={30}
            />
            <FormErrorMessage></FormErrorMessage>
          </Box>
        </Flex>
      </FormControl>
      <Divider borderColor={modeBorderColor} />

      <FormControl bg={modeFormControlBg} isRequired>
        <Flex alignItems="center">
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px" pt={3}>
              <FormLabel m={0}>Address</FormLabel>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl">
              <FormLabel pt={3}>Address</FormLabel>
            </Show>
            <Box>
              <SearchableMap
                isEdit={isEdit}
                radius={radius}
                currentLocationIndex={activeStep}
                editedFields={editedFields}
                setEditedFields={setEditedFields}
                recenterMap={recenterMap}
                setRecenterMap={setRecenterMap}
              />
            </Box>
            <FormErrorMessage></FormErrorMessage>
          </Box>
        </Flex>
      </FormControl>
      {!!passTemplateCtx.locations[activeStep] &&
        passTemplateCtx.locations[activeStep].address && (
          <>
            <FormControl
              bg={modeFormControlBg}
              borderRadius="0 15px 0 0"
              isRequired
              isDisabled
            >
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px"></Box>
                </Hide>
                <Box p="10px" w="full" bg={modeLabelBg}>
                  <Show below="2xl"></Show>
                  <Link onClick={handleAddressClick}>
                    <Text textAlign="center" fontSize="14px">
                      {passTemplateCtx.locations[activeStep]
                        ? passTemplateCtx.locations[activeStep].address
                        : ''}
                    </Text>
                  </Link>
                  <FormErrorMessage></FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />
          </>
        )}
      <FormControl
        bg={modeFormControlBg}
        borderRadius="0 15px 0 0"
        isDisabled={!passTemplateCtx.locations[activeStep]}
      >
        <Flex alignItems="center">
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0}>Distance radius (meters)</FormLabel>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl">
              <FormLabel>Distance radius (meters)</FormLabel>
            </Show>
            <SliderInput
              radius={
                passTemplateCtx.locations[activeStep]
                  ? passTemplateCtx.locations[activeStep].radius
                  : 100
              }
              max={passTemplateCtx.passType === 'eventTicket' ? 1000 : 100}
              setRadius={handleSetRadius}
              disabled={!passTemplateCtx.locations[activeStep]}
            />
            <FormErrorMessage></FormErrorMessage>
          </Box>
        </Flex>
      </FormControl>
      <Divider borderColor={modeBorderColor} />
      <FormControl
        bg={modeFormControlBg}
        borderRadius="0 15px 0 0"
        isDisabled={!passTemplateCtx.locations[activeStep]}
      >
        <Flex alignItems="center">
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0}>Notification message</FormLabel>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl">
              <FormLabel>Notification message</FormLabel>
            </Show>
            <TextareaAutosize
              value={
                passTemplateCtx.locations[activeStep]
                  ? passTemplateCtx.locations[activeStep].message
                  : ''
              }
              placeholder="Write message text here..."
              type="text"
              resize="none"
              rows={4}
              onChange={(e) => {
                passTemplateCtx.updateLocations(
                  passTemplateCtx.locations.map((loc, i) =>
                    i === activeStep ? { ...loc, message: e.target.value } : loc
                  )
                );
                if (isEdit) {
                  setEditedFields({
                    ...editedFields,
                    locations: true,
                  });
                }
              }}
              onBlur={() => {
                const value = passTemplateCtx.locations[activeStep]?.message;
                if (value.trim() === '') {
                  passTemplateCtx.updateLocations(
                    passTemplateCtx.locations.map((loc, i) =>
                      i === activeStep ? { ...loc, message: '' } : loc
                    )
                  );
                  if (isEdit) {
                    setEditedFields({
                      ...editedFields,
                      locations: true,
                    });
                  }
                }
              }}
            />
            <FormErrorMessage></FormErrorMessage>
          </Box>
        </Flex>
      </FormControl>
      <Divider borderColor={modeBorderColor} />
      <FormControl bg={modeFormControlBg} borderRadius="0 15px 0 0">
        <Flex alignItems="center">
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px"></Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl"></Show>
            <Flex
              w="full"
              justifyContent="space-between"
              mt={1}
              px={2}
              align="center"
            >
              <Button size="xs" w="25px" onClick={handleRemoveLocation}>
                Delete
              </Button>
              <Button
                size="xs"
                w="25px"
                onClick={handleLocationCountChange}
                isDisabled={
                  (!passTemplateCtx.locations[activeStep] &&
                    passTemplateCtx.locationCount > 0) ||
                  (!!passTemplateCtx.locations[activeStep] &&
                    (!passTemplateCtx.locations[activeStep].name ||
                      !passTemplateCtx.locations[activeStep].latitude))
                }
              >
                <CustomPlusIcon w="12px" />
                <Text ml={1}>New</Text>
              </Button>
            </Flex>
          </Box>
        </Flex>
      </FormControl>
    </>
  );
}

export default LocationDetails;
