import {
  Box,
  Text,
  Flex,
  Image,
  HStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { useCallback, useContext, useMemo, useState } from 'react';
import { CustomDeleteMultiIcon } from '../../theme/multicolor-icons/CustomDeleteMultiIcon';
import PassTemplateContext from '../../store/client/PassTemplateContext';
import { MeContext } from '../../context/MeContext';
import { TWENTY_MB } from '../../utils/consts';
import CustomToast from '../../common/CustomToast';
import { useEffect } from 'react';
import DeleteImageModal from '../modals/DeleteImageModal';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap-reverse',
  padding: '10px 10px 20px',
  borderWidth: 2,
  borderRadius: '15px',
  borderColor: '#DDDDDD',
  borderStyle: 'dashed',
  backgroundColor: '#FFFFFF',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  fontSize: '12px',
  fontFamily: 'Inter Medium, sans-serif',
  position: 'relative',
  minheight: '114px',
};

const squareStyle = {
  width: '142px',
  height: '142px',
};

const focusedStyle = {
  borderColor: 'green',
};

const acceptStyle = {
  borderColor: '#FF8477',
  backgroundColor: 'rgba(255, 132, 119, 0.15)',
};

const rejectStyle = {
  borderColor: '#E53E3E',
};

const darkModeStyle = {
  backgroundColor: '#3B4851',
  borderColor: 'rgba(221, 221, 221, 0.5)',
};

function CustomDropzone({
  image,
  isImageAdded,
  isSquare,
  parent,
  explanation,
  accept,
  updateDroppedImage,
  lowerCaseStampNumber,
  upperCaseStampNumber,
  removable,
  setShowCustomDropzone,
  isEdit,
  editedFields,
  setEditedFields,
}) {
  const passTemplateCtx = useContext(PassTemplateContext);
  const toast = useToast();
  const modeTextColor = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeIsDark = useColorModeValue(false, true);
  const [isDeleteImageOpen, setIsDeleteImageOpen] = useState(false);
  const meCtx = useContext(MeContext);
  const { watermarkEnabled } = meCtx.state;

  function updateFile(file) {
    isImageAdded(file);

    updateDroppedImage(file);
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      const checkImageSize =
        (parent === 'banner_image' || parent === 'google_banner_image') &&
        watermarkEnabled;
      const MIN_WIDTH = 500;
      const MIN_HEIGHT = 200;

      acceptedFiles.forEach((file) => {
        const img = new window.Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (
            (checkImageSize &&
              img.width >= MIN_WIDTH &&
              img.height >= MIN_HEIGHT) ||
            !checkImageSize
          ) {
            const fileWithPreview = Object.assign(file, {
              preview: URL.createObjectURL(file),
            });
            updateFile(fileWithPreview);
          } else {
            toast({
              render: (props) => (
                <CustomToast
                  status="warning"
                  title="Image too small"
                  description={`Please upload an image of at least ${MIN_WIDTH}x${MIN_HEIGHT} pixels.`}
                  onClose={props.onClose}
                  id={props.id}
                />
              ),
            });
          }
        };
      });
    },
    [toast, updateFile]
  );

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
    // acceptedFiles,
  } = useDropzone({
    multiple: false,
    maxFiles: 1,
    maxSize: TWENTY_MB,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    onDrop,
  });

  const onDeleteImageOpen = () => {
    setIsDeleteImageOpen(true);
  };

  const onDeleteImageClose = () => {
    setIsDeleteImageOpen(false);
  };

  useEffect(() => {
    if (fileRejections.length > 0) {
      return toast({
        render: (props) => (
          <CustomToast
            status="warning"
            title="Please use recommended image size."
            description="Less than 20MB."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections.length]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isSquare ? squareStyle : {}),
      ...(isSquare ? { padding: '37px 29px 21px' } : { padding: '18px' }),
      ...(modeIsDark ? darkModeStyle : {}),
      ...(parent === 'monochrome_badge' ? darkModeStyle : {}),
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFocused, isDragAccept, isDragReject, isSquare, modeIsDark]
  );

  function getImageFromParent(parent) {
    if (parent === 'logo_image') {
      return passTemplateCtx.logoImage;
    }

    if (parent === 'icon_logo') {
      return passTemplateCtx.iconLogo;
    }

    if (parent === 'thumbnail_image') {
      return passTemplateCtx.thumbnailImage;
    }

    if (parent === 'monochrome_badge') {
      return passTemplateCtx.monochromeBadge;
    }

    if (parent === 'banner_image') {
      return passTemplateCtx.bannerImage;
    }

    if (parent === 'google_banner_image') {
      return passTemplateCtx.googleBannerImage;
    }

    if (parent === 'stamp_image') {
      return passTemplateCtx[`stamp${upperCaseStampNumber}Image`];
    }

    return null;
  }

  return (
    <Box cursor="pointer">
      <Flex flexDir={{ base: 'column', '2xl': 'column' }}>
        {/* {image?.src === null && ( */}
        <Box flexShrink="0">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Image
              maxH="full"
              maxW={
                parent === 'logo_image'
                  ? { base: '100%', sm: '240px', xl: '165px' }
                  : parent === 'banner_image' ||
                      parent === 'google_banner_image' ||
                      parent === `stamp_image`
                    ? // 286px
                      { base: '100%', sm: '336px', xl: '165px' }
                    : '100%'
              }
              src={getImageFromParent(parent) ?? image.src}
              alt=""
              alignSelf="flex-start"
              flexShrink={0}
            />
            <HStack
              pos={
                parent === 'icon_logo' ||
                parent === 'thumbnail_image' ||
                parent === 'monochrome_badge'
                  ? 'absolute'
                  : 'relative'
              }
              top={
                parent === 'icon_logo' ||
                parent === 'thumbnail_image' ||
                parent === 'monochrome_badge'
                  ? '10px'
                  : '-8px'
              }
              right={
                parent === 'icon_logo' ||
                parent === 'thumbnail_image' ||
                parent === 'monochrome_badge'
                  ? '20px'
                  : '-6px'
              }
              alignSelf="flex-end"
              flexShrink={0}
              mb="4px"
              className={modeIsDark ? 'multicolorDarkBg' : ' '}
            >
              <Text
                color={parent === 'monochrome_badge' ? 'white' : modeTextColor}
                fontSize="12px"
              >
                Change{' '}
                {parent === 'banner_image' ||
                parent === 'google_banner_image' ||
                parent === `stamp_image` ||
                parent === 'thumbnail_image' ||
                parent === 'monochrome_badge'
                  ? 'image'
                  : 'logo'}
              </Text>
              {!!removable && (
                <CustomDeleteMultiIcon
                  fontSize="16px"
                  onClick={(event) => {
                    event.stopPropagation(); // Stop event propagation
                    onDeleteImageOpen();
                  }}
                />
              )}
            </HStack>
            {/* <CustomDropzoneMultiIcon fontSize="38px" />
              <Text lineHeight="120%" color="primaryDark" textAlign="center" mt={1}>
                Drag and drop here
                <br />
                or
              </Text>
              <Heading lineHeight="120%" color="brand" fontSize="12px">
                Choose file
              </Heading> */}
          </div>
        </Box>
        <DeleteImageModal
          isOpen={isDeleteImageOpen}
          onClose={onDeleteImageClose}
          parent={parent}
          setShowCustomDropzone={setShowCustomDropzone}
          isEdit={isEdit}
          editedFields={editedFields}
          setEditedFields={setEditedFields}
        />

        {/* )} */}

        {/* {image.src && (
          <>
            <Box flexShrink="0">
              <Box style={styleForUploadedImage} pos="relative" h="114px">
                <CustomDeleteMultiIcon
                  pos="absolute"
                  top="12px"
                  right="12px"
                  fontSize="16px"
                  cursor="pointer"
                  // onClick={onDeleteImageOpen}
                />
                <Image
                  maxH="full"
                  maxW={parent === 'logo_image' ? { base: '100%', sm: '240px' } : '100%'}
                  src={getImageFromParent(parent) ?? image.src}
                  alt=""
                />
              </Box>
            </Box>
            <DeleteImageModal
              isOpen={isDeleteImageOpen}
              onClose={onDeleteImageClose}
              parent={parent}
            />
          </>
        )} */}

        <Text
          color={modeTextColor}
          textStyle="bodyFamilyRegular"
          fontSize="12px"
          lineHeight="120%"
          mt={{ base: '14px', '2xl': '14px' }}
          ml={{ base: '0', '2xl': '0' }}
          maxW={{ base: 'auto', '2xl': 'auto' }}
        >
          {explanation}
        </Text>
      </Flex>

      {/*<Box*/}
      {/*  color="brand"*/}
      {/*  textStyle="bodyFamilyRegular"*/}
      {/*  fontSize="12px"*/}
      {/*  lineHeight="120%"*/}
      {/*  mt="14px"*/}
      {/*>*/}
      {/*  {filesList}*/}
      {/*</Box>*/}
    </Box>
  );
}

export default CustomDropzone;
