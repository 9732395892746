import { Box, Flex, Text, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import Select, { components } from 'react-select';
import React from 'react';
import EventsRenderStatus from '../client/events/EventsRenderStatus';
import PassesRenderStatus from '../client/passes/PassesRenderStatus';
import ReadersRenderStatus from '../client/readers/ReadersRenderStatus';
import classes from '../../utils/cssHelperClasses.module.css';

export const CustomReactSelect = React.forwardRef((props, ref) => {
  const modeInputBg = useColorModeValue(
    '#fff',
    props.liveEventData ? '#3B4851' : '#29323A'
  );
  const modePlaceholderColor = useColorModeValue(
    'rgba(59, 72, 81, 0.6)',
    props.liveEventData ? '#ddd' : '#7F8488'
  );
  const modeMenuBg = useColorModeValue('#fff', '#3B4851');
  const modeMenuColor = useColorModeValue('#29323A', '#fff');
  const modeMenuBgHover = useColorModeValue('rgba(221, 221, 221, 0.5)', '#29323A');
  const modeMenuColorDisabled = useColorModeValue('#ddd', '#5A6066');
  const modeBorderHover = useColorModeValue('#3B4851', '#fff');
  const [isSmallerThan320px] = useMediaQuery('(max-width: 320px)');
  const modeInputBgPassStatus = useColorModeValue('rgba(221, 221, 221, 0.5)', '#29323A');

  // select pass status styles
  const reactSelectPassFilterStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: '0px 10px',
      marginTop: '10px',
      backgroundColor: modeInputBgPassStatus,
      display: 'flex',
      borderRadius: '25px',
      border: '2px solid transparent',
      boxShadow: 'none',
      ':hover': {
        borderColor: modeBorderHover,
        transition: 'all .3s ease-in-out',
        cursor: 'pointer',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: modeMenuColor,
      backgroundColor: modeMenuBg,
      ':hover': {
        backgroundColor: modeMenuBgHover,
        color: modeMenuColor,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      // zIndex: '20',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0px',
      zIndex: '5',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: modePlaceholderColor,
      fontSize: '14px',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: modeMenuColor,
      ':hover': {
        color: modeMenuColor,
        cursor: 'pointer',
      },
      svg: {
        width: '1em',
        height: '1em',
      },
    }),
    indicatorSeparator: (provided, state) => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      paddingRight: '0px',
      color: modeMenuColor,
      ':hover': {
        color: modeMenuColor,
        cursor: 'pointer',
      },
      svg: {
        width: '1em',
        height: '1em',
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: '#3B4851',
      backgroundColor: '#DDDD',
      borderRadius: '25px',
      padding: '0px 5px',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: '#3B4851',
      ':hover': {
        color: '#3B4851',
      },
    }),
  };

  // select reader styles
  const reactSelectReaderStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: '4px 10px',
      backgroundColor: state.isDisabled ? '#cccccc' : modeInputBg,
      display: 'flex',
      borderRadius: '25px',
      border: '2px solid transparent',
      boxShadow: 'none',
      ':hover': {
        borderColor: modeBorderHover,
        transition: 'all .3s ease-in-out',
        cursor: 'pointer',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: !state.data.setupConfirmed ? modeMenuColorDisabled : modeMenuColor,
      backgroundColor: modeMenuBg,
      wordBreak: 'break-word',
      ':hover': {
        backgroundColor: !state.data.setupConfirmed ? 'none' : modeMenuBgHover,
        color: !state.data.setupConfirmed ? 'none' : modeMenuColor,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      // zIndex: '20',
      borderRadius: '10px',
      border: 'none',
      boxShadow: 'none',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0px',
      zIndex: '5',
      boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: modePlaceholderColor,
      fontSize: '14px',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: modeMenuColor,
      ':hover': {
        color: modeMenuColor,
        cursor: 'pointer',
      },
      svg: {
        width: '1em',
        height: '1em',
      },
    }),
    indicatorSeparator: (provided, state) => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      paddingRight: '0px',
      color: modeMenuColor,
      ':hover': {
        color: modeMenuColor,
        cursor: 'pointer',
      },
      svg: {
        width: '1em',
        height: '1em',
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: '#3B4851',
      backgroundColor: '#DDDD',
      borderRadius: '25px',
      padding: '0px 5px',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: '#3B4851',
      ':hover': {
        color: '#3B4851',
      },
    }),
  };

  // select event and template styles
  const reactSelectPassTemplateStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: '0px 16px',
      display: 'flex',
      borderRadius: '25px',
      border: '2px solid transparent',
      boxShadow: 'none',
      ':hover': {
        borderColor: modeBorderHover,
        transition: 'all .3s ease-in-out',
        cursor: 'pointer',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: modeMenuColor,
      backgroundColor: modeMenuBg,
      ':hover': {
        backgroundColor: modeMenuBgHover,
        color: modeMenuColor,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      // zIndex: '20',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0px',
      zIndex: '5',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: modePlaceholderColor,
      fontSize: '14px',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: modeMenuColor,
      ':hover': {
        color: modeMenuColor,
        cursor: 'pointer',
      },
      svg: {
        width: '1em',
        height: '1em',
      },
    }),
    indicatorSeparator: (provided, state) => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      paddingRight: '0px',
      color: modeMenuColor,
      ':hover': {
        color: modeMenuColor,
        cursor: 'pointer',
      },
      svg: {
        width: '1em',
        height: '1em',
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: '#3B4851',
      backgroundColor: '#DDDD',
      borderRadius: '25px',
      padding: '0px 5px',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: '#3B4851',
      ':hover': {
        color: '#3B4851',
      },
    }),
  };

  // select pass template entity and type styles
  const reactSelectPassTemplateEntityTypeStyles = {
    singleValue: (provided, state) => ({
      ...provided,
      color: !props.liveEventData ? modeMenuColor : modeBorderHover,
      height: props.liveEventData ? '18px' : '',
      fontSize: props.liveEventData && '14px',
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: props.liveEventData ? '36px' : '',
      height: '50px',
      padding: props.liveEventData ? '2px 8px' : '4px 10px',
      backgroundColor: modeInputBg,
      borderRadius: '25px',
      border: '2px solid transparent',
      boxShadow: 'none',
      _placeholder: {
        color: 'rgba(59, 72, 81, 0.6)',
      },
      width:
        props.liveEventData && !props.dashboardPassTemplate && !isSmallerThan320px
          ? '310px'
          : props.dashboardPassTemplate
          ? '200px'
          : '',
      ':hover': {
        borderColor: modeBorderHover,
        transition: 'all .3s ease-in-out',
        cursor: 'pointer',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color:
        !props.liveEventData && state.data.status === 'passed'
          ? modeMenuColorDisabled
          : modeMenuColor,
      backgroundColor: modeMenuBg,
      wordBreak: 'break-word',
      ':hover': {
        backgroundColor:
          !props.liveEventData && state.data.status === 'passed'
            ? 'none'
            : modeMenuBgHover,
        color:
          !props.liveEventData && state.data.status === 'passed' ? 'none' : modeMenuColor,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: '20',
      borderRadius: '10px',
      border: 'none',
      boxShadow: 'none',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0px',
      zIndex: '5',
      boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: modePlaceholderColor,
      fontSize: '14px',
    }),
    indicatorSeparator: (provided, state) => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      paddingRight: !props.passTemplate ? '10px' : '0',
      paddingTop: '0',
      padding: '0',
      paddingBottom: props.liveEventData && '12px',
      color: modeMenuColor,
      ':hover': {
        color: modeMenuColor,
        cursor: 'pointer',
      },
      svg: {
        width: props.liveEventData ? '13px' : '1em',
        height: props.liveEventData ? '13px' : '1em',
      },
    }),
  };

  const reactSelectWebhookEventsStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: '5px 10px',
      height: 'fit-content',
      display: 'flex',
      borderRadius: '25px',
      border: '2px solid transparent',
      boxShadow: 'none',
      ':hover': {
        borderColor: modeBorderHover,
        transition: 'all .3s ease-in-out',
        cursor: 'pointer',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: modeMenuBg,
      ':hover': {
        backgroundColor: modeMenuBgHover,
        color: modeMenuColor,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      // zIndex: '20',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0px',
      zIndex: '5',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: modePlaceholderColor,
      fontSize: '14px',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: modeMenuColor,
      ':hover': {
        color: modeMenuColor,
        cursor: 'pointer',
      },
      svg: {
        width: '1em',
        height: '1em',
      },
    }),
    indicatorSeparator: (provided, state) => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      paddingRight: '0px',
      color: modeMenuColor,
      ':hover': {
        color: modeMenuColor,
        cursor: 'pointer',
      },
      svg: {
        width: '1em',
        height: '1em',
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: 'white',
      backgroundColor: props.isDisabled ? '#B8BCBF' : '#FA7D6F',
      borderRadius: '25px',
      padding: '0px 5px',
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: 'white',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: 'white',
      ':hover': {
        color: 'white',
      },
    }),
  };

  // custom formatting the multi readers display on create event page
  const MultiValue = (props) => {
    return (
      <components.MultiValue {...props}>
        <div>{props.data.label}</div>
      </components.MultiValue>
    );
  };

  return (
    <Select
      components={{ MultiValue }}
      ref={ref}
      {...props}
      styles={
        props.id === 'reader'
          ? reactSelectReaderStyles
          : props.id === 'passTemplate'
          ? reactSelectPassTemplateStyles
          : props.id === 'webhookEvents'
          ? reactSelectWebhookEventsStyles
          : (props.id === 'passTemplateFilter') |
            (props.id === 'groupTagFilter') |
            (props.id === 'passStatus') |
            (props.id === 'passTypeFilter') |
            (props.id === 'readerTypeFilter') |
            (props.id === 'eventFilter') |
            (props.id === 'deviceTypeFilter')
          ? reactSelectPassFilterStyles
          : (props.id === 'templateEntity') |
            (props.id === 'passType') |
            (props.id === 'event') |
            (props.id === 'deviceType') |
            (props.id === 'readerType')
          ? reactSelectPassTemplateEntityTypeStyles
          : ''
      }
      isSearchable={!!props.passTemplate}
      isClearable={!!props.rewardsInfoField}
    />
  );
});

// select reader tag label formatting
export const formatOptionLabelSelectReader = ({ label, setupConfirmed, enabled }) => (
  <Flex justifyContent="space-between">
    <Text wordBreak="break-word" maxW="70%">
      {label}
    </Text>
    <Box>
      <ReadersRenderStatus setupConfirmed={setupConfirmed} readerStatus={enabled} />
    </Box>
  </Flex>
);

// select event tag label formatting
export const formatOptionLabelSelectEvent = ({ name, status }) => {
  if (name.length < 25) {
    return (
      <Flex justifyContent="space-between">
        <Text maxW="60%" className={classes.mobileName}>
          {name}
        </Text>
        <Box>
          <EventsRenderStatus value={status} />
        </Box>
      </Flex>
    );
  } else {
    const trimmedName = name.substring(0, 22);
    return (
      <Flex justifyContent="space-between">
        <Text maxW="60%" className={classes.mobileName}>
          {trimmedName} ...
        </Text>
        <Box>
          <EventsRenderStatus value={status} />
        </Box>
      </Flex>
    );
  }
};

// select event on live event data formatting
export const formatOptionLabelSelectEventOnLiveEventData = ({ name, uuid }) => {
  if (name.length < 35) {
    return <Text value={uuid}>{name}</Text>;
  } else {
    const trimmedName = name.substring(0, 25);
    return <Text value={uuid}>{trimmedName} ...</Text>;
  }
};

// select pass template filter dropdown label formatting for Pass Create page
export const formatOptionLabelSelectPassTemplate = ({ templateName }) => {
  if (templateName?.length < 30) {
    return (
      <Text fontSize="14px" value={templateName}>
        {templateName}
      </Text>
    );
  } else {
    // const trimmedName = name.substring(0, 33);
    // return (
    //   <Text fontSize="14px" value={name}>
    //     {trimmedName} ...
    //   </Text>
    // );
  }
};
// select pass template tag in filter dropdown label formatting
export const formatOptionLabelFilterPassTemplate = ({ label }) => {
  return (
    <Text fontSize="14px" value={label}>
      {label}
    </Text>
  );
};

export const formatOptionLabelFilterEntity = ({ label }) => {
  return (
    <Text fontSize="12px" value={label} isTruncated>
      {label}
    </Text>
  );
};

export const formatOptionLabelFilterGroupTag = ({ label }) => {
  return (
    <Text fontSize="14px" value={label}>
      {label}
    </Text>
  );
};

// formatting for event filter
export const formatOptionLabelFilterEvent = ({ attributes }) => {
  const { name } = attributes;

  if (name?.length < 30) {
    return (
      <Text fontSize="14px" value={name}>
        {name}
      </Text>
    );
  } else {
    const trimmedName = name.substring(0, 33);
    return (
      <Text fontSize="14px" value={name}>
        {trimmedName} ...
      </Text>
    );
  }
};

// select pass status tag in filter dropdown label formatting
export const formatOptionLabelSelectPassStatus = ({ status }) => (
  <Box>
    <PassesRenderStatus value={status} />
  </Box>
);

// select entity on pass template create page
export const formatOptionLabelSelectEntity = ({ label, value }) => {
  if (label.length < 30) {
    return (
      <Text className={classes.mobileName} value={value}>
        {label}
      </Text>
    );
  } else {
    const trimmedName = label.substring(0, 33);
    return (
      <Text className={classes.mobileName} value={value}>
        {trimmedName} ...
      </Text>
    );
  }
};

// select pass type on pass template create page
export const formatOptionLabelSelectPassType = ({ label, value }) => {
  return (
    <Text className={classes.mobileName} value={value}>
      {label}
    </Text>
  );
};

export const formatOptionLabelSelectDeviceType = ({ label, value }) => {
  return (
    <Text className={classes.mobileName} value={value}>
      {label}
    </Text>
  );
};
